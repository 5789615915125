
.steps {
    background-color: #E9EDEC;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding: 15px 200px 15px 200px;
    .step {
        font-family: Optima-Bold;
        color: #2B2B2B;
        letter-spacing: 0;
        font-weight: 700;
    }
    
}

:global(.ant-steps-item-active) {
    :global(.ant-steps-item-title) {
        color: #5E81B0 !important;
    }
}

:global(.ant-steps-item-finish) {
    :global(.ant-steps-item-title) {
        color: #5E81B0 !important;
    }
}