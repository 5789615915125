.page {
    height: 100vh;
    overflow: hidden;
    .container {
        height: calc(100vh - 140px - 360px);
        padding: 50px 100px 30px 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;


        .left, .right {
            height: 100%;
            background-color: #FFFFFF;
            overflow-y: scroll;
            border: 1px solid #979797;
        }


        .left {
            width: 66%;
            margin-right: 20px;
            .leftContaier {
                .leftTop {
                    padding: 40px 70px 0 55px;
                    .researchTopic, .researchQuestion {
                        height: 40px;
                        font-family: Optima-Regular;
                        font-size: 24px;
                        color: #131415;
                        letter-spacing: 0;
                        font-weight: 400;
                        display: flex;
                        align-items: center;
                        input {
                            flex: 1;
                            width: 100%;
                            border: none;
                            border-bottom: 1px solid #979797;
                            font-family: Optima-Regular;
                            color: #131415;
                            letter-spacing: 0;
                            font-weight: 400;
                            outline: none;
                        }
                    }
                    .researchQuestion {
                        margin-top: 20px;
                        position: relative;
                        .addQuestionBtn {
                            position: absolute;
                            right: 0;
                            bottom: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-family: Optima-Regular;
                            font-size: 16px;
                            color: #131415;
                            letter-spacing: 0;
                            font-weight: 400;
                            padding: 5px;
                            cursor: pointer;
                            img {
                                width: 10px;
                            }
                        }
                    }
                }
                .leftCenter {
                    margin: 45px 70px 30px 55px;
                    padding: 0 30px 50px 60px;
                    border: 1px solid #979797;
                   
                }
                .leftContaierStep3 {
                    margin: 45px 0 30px 0;
                    padding: 40px 70px 0 55px;
                    border-top: 1px solid #979797;
                    .step3Content {
                        height: 600px;
                        overflow-y: scroll;
                        .reminder {
                            font-family: Optima-Regular;
                            color: #131415;
                            letter-spacing: 0;
                            font-weight: 400;
                            .title {
                                font-size: 26px;
                                margin-bottom: 10px;
                            }
                            .move {
                                font-size: 22px;
                            }
                        }
                        .moveInput {
                            margin-bottom: 30px;
                            .moveInputItem {
                                .inputContainer {
                                    .inputItem {
                                        display: flex;
                                        align-items: flex-start;
                                        border: 1px solid #979797;
                                        padding: 20px;
                                        margin-top: 30px;
                                        .inputTitle {
                                            font-family: Optima-Regular;
                                            font-size: 20px;
                                            letter-spacing: 0;
                                            line-height: 28px;
                                            font-weight: 400;
                                            color: #5E81B0;
                                            margin-top: 7px;
                                        }
                                        .inputContent {
                                            flex: 1;
                                            .textarea {
                                                width: 100%;
                                                font-family: Optima-Regular;
                                                font-size: 20px;
                                                letter-spacing: 0;
                                                line-height: 28px;
                                                font-weight: 400;
                                                color: #131415;
                                            }
                                        }
                                        .deleteInput {
                                            width: 30px;
                                            cursor: pointer;
                                        }
                                    }
                                    
                                }
                            }
                        }
                    }
                    .buttomBtn {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
            }
        }
        .right {
            flex: 1;
            .rightContainer {
                padding-bottom: 30px;
                .step1Right {
                    .title {
                        padding: 25px 0 25px 30px;
                        border-bottom: 1px solid #979797;
                        .addConcept {
                            font-family: Optima-Regular;
                            font-size: 24px;
                            color: #5E81B0;
                            letter-spacing: 0;
                            font-weight: 400;  
                        }
                    }
                    .content {
                        .conceptTabs {
                            padding-left: 60px;
                            height: 700px;
                            overflow-y: scroll;
                        }
                    }
                }
            }
        }
    }
    .footer {
        width: 100%;
        position: absolute;
        bottom: 0;
        background: #B4BEBB;
        .footerContainer {
            display: flex;
            height: 140px;
            justify-content: space-between;
            align-items: center;
            padding: 0 200px 0 100px;
            font-family: Optima-Regular;
            color: #131415;
            letter-spacing: 0;
            font-weight: 400;
            .footerLeft {
                font-family: Optima-Regular;
                font-size: 22px;
                color: #131415;
                letter-spacing: 0;
                font-weight: 400;
                width: 62%;
            }
            .nextStep {
                width: 335px;
                font-size: 22px;
            }
        }
        
    }
}

.spin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}