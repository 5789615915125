.container {
    height: calc(100vh - 140px - 360px);
    padding: 50px 100px 30px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;


    .left, .right {
        height: 100%;
        background-color: #FFFFFF;
        // overflow: hidden;
        border: 1px solid #979797;
    }

    .left {
        width: 66%;
        margin-right: 20px;
        .leftContaier {
            height: 100%;
            display: flex;
            overflow-y: scroll;
            flex-direction: column;
            position: relative;
            .leftTop {
                flex-shrink: 0;
                padding: 40px 70px 30px 55px;
                border-bottom: 1px solid #979797;
                .researchTopic, .researchQuestion {
                    height: 40px;
                    font-family: Optima-Regular;
                    font-size: 24px;
                    color: #131415;
                    letter-spacing: 0;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    input {
                        flex: 1;
                        width: 100%;
                        border: none;
                        border-bottom: 1px solid #979797;
                        font-family: Optima-Regular;
                        color: #131415;
                        letter-spacing: 0;
                        font-weight: 400;
                        outline: none;
                    }
                }
                .researchQuestion {
                    margin-top: 20px;
                    position: relative;
                    .addQuestionBtn {
                        position: absolute;
                        right: 0;
                        bottom: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Optima-Regular;
                        font-size: 16px;
                        color: #131415;
                        letter-spacing: 0;
                        font-weight: 400;
                        padding: 5px;
                        cursor: pointer;
                        img {
                            width: 10px;
                        }
                    }
                }
            }
            .leftCenter {
                margin: 45px 70px 30px 55px;
                flex: 1;
                // height: calc(100% - 0px);
            }
            .leftContaierStep3 {
                margin: 0 0 80px 0;
                .step3Content {
                    .reminder {
                        font-family: Optima-Regular;
                        color: #131415;
                        letter-spacing: 0;
                        font-weight: 500;
                        .title {
                            font-size: 28px;
                            margin-bottom: 10px;
                        }
                        .move {
                            font-size: 24px;
                            margin-top: 4px;
                        }
                    }
                    .moveInput {
                        margin-bottom: 30px;
                        .moveInputItem {
                            .inputContainer {
                                .inputItem {
                                    display: flex;
                                    align-items: flex-start;
                                    border: 1px solid #979797;
                                    padding: 20px;
                                    margin-top: 30px;
                                    .inputTitle {
                                        font-family: Optima-Regular;
                                        font-size: 20px;
                                        letter-spacing: 0;
                                        line-height: 28px;
                                        font-weight: 400;
                                        color: #5E81B0;
                                        margin-top: 7px;
                                    }
                                    .inputContent {
                                        flex: 1;
                                        .textarea {
                                            width: 100%;
                                            font-family: Optima-Regular;
                                            font-size: 20px;
                                            letter-spacing: 0;
                                            line-height: 28px;
                                            font-weight: 400;
                                            color: #131415;
                                        }
                                    }
                                    .deleteInput {
                                        width: 30px;
                                        cursor: pointer;
                                    }
                                }
                                
                            }
                        }
                    }
                }
                .buttomBtn {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: fixed;
                    bottom: 230px;
                }
            }
        }
    }
    .right {
        flex: 1;
        .rightContainer {
            height: 100%;
            padding-bottom: 30px;
        }
    }
}
.footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: #B4BEBB;
    .footerContainer {
        display: flex;
        height: 140px;
        justify-content: space-between;
        align-items: center;
        padding: 0 200px 0 100px;
        font-family: Optima-Regular;
        color: #131415;
        letter-spacing: 0;
        font-weight: 400;
        .footerLeft {
            font-family: Optima-Regular;
            font-size: 22px;
            color: #131415;
            letter-spacing: 0;
            font-weight: 400;
            width: 62%;
        }
        .nextStep {
            width: 335px;
            font-size: 22px;
        }
    }
    
}

.deleteModal {
    text-align: center;
    padding: 80px 0 50px 0;
    .modalContent {
        display: flex;
        justify-content: center;
        align-items: center;
        .deleteProject {
            width: 104px;
        }
        .confrimText {
            margin-left: 50px;
            text-align: left;
            font-family: Optima-Regular;
            font-size: 30px;
            color: #131415;
            letter-spacing: 0;
            font-weight: 400;
        }
    }
    .buttonGroup {
        display: flex;
        justify-content: center;
        margin-top: 90px;
        .yesBtn, .noBtn {
            width: 252px;
            height: 60px;
            border-radius: 14px;
            font-size: 24px;
        }
        .yesBtn {
            font-family: Optima-Regular;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
        }
        .noBtn {
            margin-left: 40px;
            font-family: Optima-Regular;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
        }
    }
}
