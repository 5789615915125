.body {
  margin-top: 0px;
  background-color:  #FFFFFF;
  display: flex;
  flex-wrap: nowrap;
  padding: 0px 100px 0px 80px;
  align-items: center;
  justify-content: space-between;
  height: 140px;

  .left {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    .logoLeft {
      width: 69px;
      margin-right: 30px;
    }
    .logoRight {
      width: 210px;
    }
  }

  .right {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    .userInfo {
      display: flex;
      align-items: center;
      .userName {
        font-family: Optima-Regular;
        font-size: 24px;
        color: #5E81B0;
        letter-spacing: 0;
        font-weight: 400;
      }
      .userArrow {
        width: 17px;
        margin-left: 20px;
      }
    }
  }
  
  .right > div, .left > div {
    cursor: pointer;
  }
}

.myAccount, .logOut {
  font-family: PingFangSC-Regular;
  font-size: 20px;
  color: #343434;
  letter-spacing: 0;
  line-height: 60px;
  font-weight: 400;
  width: 180px;
  margin-left: 17px;
  cursor: pointer;
  white-space: nowrap;
}
.divider {
  border: 0.1px solid #D3D3D3;
  width: 186px;
}

.pop:global.ant-popover-content {
  background: #FFFFFF;
  border: 1px solid #DEDEDE;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16);
  border-radius: 8px;
  height: 108px;
}