.container {
    height: calc(100vh - 140px - 360px);
    padding: 50px 100px 30px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;


    .left, .right {
        height: 100%;
        background-color: #FFFFFF;
        border: 1px solid #979797;
    }

    .left {
        width: 66%;
        margin-right: 20px;
        .leftContaier {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
        }
    }
    .right {
        flex: 1;
        .rightContainer {
            height: 100%;
            padding-bottom: 30px;
        }
    }
}
.footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: #B4BEBB;
    .footerContainer {
        display: flex;
        height: 140px;
        justify-content: space-between;
        align-items: center;
        padding: 0 200px 0 100px;
        font-family: Optima-Regular;
        color: #131415;
        letter-spacing: 0;
        font-weight: 400;
        .footerLeft {
            font-family: Optima-Regular;
            font-size: 22px;
            color: #131415;
            letter-spacing: 0;
            font-weight: 400;
            width: 62%;
        }
        .nextStep {
            width: 335px;
            font-size: 22px;
        }
    }
    
}