.page {
    .body {
        margin: 30px 0 15px;
        display: flex;
        justify-content: center;
        .container {
            width: 90%;
            height: 80vh;
            overflow-y: scroll;
            background: #FFFFFF;
            border: 1px solid #979797;
            .title {
                padding: 30px 0 30px 45px;
                font-family: Optima-Regular;
                font-size: 28px;
                color: #131415;
                letter-spacing: 0;
                font-weight: 500;
                border-bottom: 1px solid #979797;
            }
            .content {
                white-space: pre-wrap;
                padding: 40px 0 30px 40px;
                font-family: Optima-Regular;
                font-size: 24px;
                color: #131415;
                letter-spacing: 0;
                line-height: 30px;
                font-weight: 400;
            }
        }
    }
    .download {
        width: 95%;
        display: flex;
        justify-content: flex-end;
    }
}

.spin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}