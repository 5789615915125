.container {
    height: calc(100vh - 140px - 360px);
    padding: 50px 100px 30px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left, .right {
        height: 100%;
        background-color: #FFFFFF;
        border: 1px solid #979797;
    }

    .left {
        width: 60%;
        flex-shrink: 0;
        margin-right: 20px;
        .leftContaier {
            overflow-y: scroll;
            height: 100%;
            .leftTop {
                padding: 40px 70px 0 55px;
                .researchTopic, .researchQuestion {
                    height: 40px;
                    font-family: Optima-Regular;
                    font-size: 24px;
                    color: #131415;
                    letter-spacing: 0;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    input {
                        flex: 1;
                        width: 100%;
                        border: none;
                        border-bottom: 1px solid #979797;
                        font-family: Optima-Regular;
                        color: #131415;
                        letter-spacing: 0;
                        font-weight: 400;
                        outline: none;
                    }
                }
                .researchQuestion {
                    margin-top: 20px;
                    position: relative;
                    .addQuestionBtn {
                        position: absolute;
                        right: 0;
                        bottom: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Optima-Regular;
                        font-size: 16px;
                        color: #131415;
                        letter-spacing: 0;
                        font-weight: 400;
                        padding: 5px;
                        cursor: pointer;
                        img {
                            width: 10px;
                        }
                    }
                }
            }
            .leftCenter {
                margin: 45px 70px 30px 55px;
                padding: 0 30px 50px 60px;
                border: 1px solid #979797;
               
            }
        }
    }
    .right {
        width: 40%;
        flex-shrink: 0;
        .rightContainer {
            height: 100%;
            width: 100%;
            padding-bottom: 30px;

            .step1Right {
                height: 100%;
                width: 100%;
                .title {
                    display: flex;
                    justify-content: flex-end;
                    padding: 25px 0 25px 30px;
                    border-bottom: 1px solid #979797;
                    .addConcept {
                        margin-right: 30px;
                        font-family: Optima-Regular;
                        font-size: 24px;
                        color: #5E81B0;
                        letter-spacing: 0;
                        font-weight: 400;  
                    }
                }
                .content {
                    overflow-y: scroll;
                    height: calc(100% - 118px);
                    padding-top: 8px;
                    .conceptTabs {
                        padding: 0 30px;
                       
                    }
                }
            }
        }
    }
}
.footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: #B4BEBB;
    .footerContainer {
        display: flex;
        height: 140px;
        justify-content: space-between;
        align-items: center;
        padding: 0 200px 0 100px;
        font-family: Optima-Regular;
        color: #131415;
        letter-spacing: 0;
        font-weight: 400;
        .footerLeft {
            font-family: Optima-Regular;
            font-size: 22px;
            color: #131415;
            letter-spacing: 0;
            font-weight: 400;
            width: 62%;
        }
        .nextStep {
            width: 335px;
            font-size: 22px;
        }
    }
    
}