.page {

    .container {
        display: flex;
        height: calc(100vh - 140px);
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 100px 0 80px;
        
        .projectGroup {
            max-width: 100vw;
            overflow-x: scroll;
            display: flex;
            // justify-content: center;
            
            .createProject {
                flex-shrink: 0;
                padding: 80px 35px 60px 35px;
                margin-left: 50px;
                font-family: Optima-Regular;
                font-size: 24px;
                color: #5E81B0;
                letter-spacing: 0;
                font-weight: 400;
                text-align: center;
                border: 2px dashed #5E81B0;
                border-radius: 8px;
                cursor: pointer;
                .createIcon {
                    margin-top: 40px;
                    width: 82px;
                    margin-bottom: 70px;
                }
            }
            .reviseProject, .viewProject {
                flex-shrink: 0;
                background: #FFFFFF;
                width: 390px;
                border: 4px solid #5E81B0;
                padding: 20px 25px;
                margin-left: 50px;
                .cardHeader {
                    font-family: Optima-Regular;
                    font-size: 28px;
                    color: #CCCCCC;
                    letter-spacing: 0;
                    font-weight: 400;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .deleteIcon {
                        width: 28px;
                        height: 30px;
                        cursor: pointer;
                    }
                }
                .projectTitle {
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    height: 250px;
                    font-family: Optima-Regular;
                    font-size: 34px;
                    color: #131415;
                    font-weight: 400;
                    z-index: 2;
                }
                .selectBtn, .selectBtn2 {
                    flex: 1;
                    width: 390px;
                    font-family: Optima-Regular;
                    font-size: 24px;
                    color: #FFFFFF;
                    text-align: center;
                    font-weight: 400;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .selectBtnTxt {
                        flex: 1;
                    }
                    .btnArrow {
                        width: 9px;
                    }
                }
                .createTime {
                    margin-top: 16px;
                    font-family: Optima-Regular;
                    font-size: 20px;
                    color: #131415;
                    letter-spacing: 0;
                    font-weight: 400;
                }
            }
            .viewProject {
                position: relative;
                .finishIcon {
                    width: 133px;
                    position: absolute;
                    top: 70px;
                    right: 20px;
                    z-index: 1;
                }
                .selectBtn {
                    font-family: Optima-Regular;
                    font-size: 24px;
                    color: #5E81B0;
                    letter-spacing: 0;
                    font-weight: 400;
                    justify-content: center;
                    border: 1px solid #5E81B0;
                }

                .selectBtn2 {
                    font-family: Optima-Regular;
                    font-size: 24px;
                    letter-spacing: 0;
                    font-weight: 400;
                    justify-content: center;
                    border: 1px solid #5E81B0;
                }
            }
        }
        .description {
            margin-top: 125px;
            padding: 0 200px;
            line-height: 1.4;
            font-family: Optima-Regular;
            font-size: 24px;
            color: #585858;
            letter-spacing: 0;
            font-weight: 400;
        }
    }
}

.modalClose {
    width: 33px;
    img {
        width: 100%;
    }
}

.projectModal {
    text-align: center;
    padding: 60px 0 50px 0;
    .modalTitle {
        font-family: Optima-Regular;
        font-size: 30px;
        color: #5E81B0;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
    }
    .input {
        width: 585px;
        height: 90px;
        margin: 50px 0 65px 0;
        text-align: center;
        background: #FCFCFC;
        border: 1px solid #979797;
        border-radius: 8px;
        font-family: Optima-Regular;
        font-size: 30px;
        color: #131415;
        letter-spacing: 0;
        font-weight: 400;
    }
    input:focus {
        outline: none;
    }
    .saveBtn {
        width: 250px;
        height: 60px;
        background: #5E81B0;
        border: 1px solid #979797;
        border-radius: 14px;
        font-family: Optima-Regular;
        font-size: 24px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
    }
}

.deleteModal {
    text-align: center;
    padding: 80px 0 50px 0;
    .modalContent {
        display: flex;
        justify-content: center;
        align-items: center;
        .deleteProject {
            width: 104px;
        }
        .confrimText {
            margin-left: 50px;
            text-align: left;
            font-family: Optima-Regular;
            font-size: 30px;
            color: #131415;
            letter-spacing: 0;
            font-weight: 400;
        }
    }
    .buttonGroup {
        display: flex;
        justify-content: center;
        margin-top: 90px;
        .yesBtn, .noBtn {
            width: 252px;
            height: 60px;
            border-radius: 14px;
            font-size: 24px;
        }
        .yesBtn {
            font-family: Optima-Regular;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
        }
        .noBtn {
            margin-left: 40px;
            font-family: Optima-Regular;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
        }
    }
}

.btnContainer {
    display: flex;

    gap: 20px;
}