.body {
  display: flex;
  flex-wrap: nowrap;
  // padding-top: 300px;
  height: 100vh;
  align-items: center;

  .left {
    width: 45%;
    padding-left: 143px;
    margin-right: 140px;
    .logoGroup {
      .logoLeft {
        width: 69px;
        margin-right: 30px;
      }
      .logoRight {
        width: 210px;
      }
    }
    .leftContent {
      line-height: 1.6;
      font-family: Optima-Regular;
      font-size: 25px;
      color: #5E81B0;
      letter-spacing: 0;
      font-weight: 400;
      margin-top: 25px;
      .components {
        margin-left: 15px;
      }
    }
  }

  .right {
    width: 50%;
    flex: 1;
    margin-top: 80px;
    position: relative;
    .rightBox {
      width: 733px;
      padding:103px 58px 89px 58px;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      box-shadow: 0 3px 20px 10px rgba(0,0,0,0.09);
      border-radius: 15px;
      .rightTitle {
        font-family: Optima-Regular;
        font-size: 36px;
        color: #5E81B0;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
      }
      .account, .password {
        overflow: hidden;
        display: flex;
        flex-flow: row nowrap;
        background: #F5F5F5;
        border: 1px solid #D7D7D7;
        border-radius: 15px;
      }
      .account {
        margin-top: 88px;
      }
      .password {
        margin-top: 46px;
      }
      .accountImg, .pswImg {
        margin: 25px 25px 28px 27px;
      }
      .accountImg {
        width: 40px;
      }
      .pswImg {
        width: 35px;
      }
      .input {
        // width: 484px;
        background-color: #F5F5F5;
        border-style: none;
        font-family: ArialMT;
        font-size: 24px;
        width: 100%;
        color: #6D7278;
      }
      
      input:focus {
        outline: none;
      }
      .termsBox {
        display: flex;
        justify-content: center;
      }
      .terms {
        cursor: pointer;
        position: relative;
        top: 20px;
        margin-top: 50px;
        font-family: Optima-Italic;
        font-size: 24px;
        color: #5E81B0;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        text-decoration: underline;
      }
    }
  }

}

.buttonNormal, .buttonDisable {
  margin: 61px auto 0px auto;
  width: 50%;
  line-height: 86px;
  border-radius: 10px;
  font-family: PingFangSC-Regular;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  color: #FFFFFF;
}

.buttonNormal {
  background-color: #5E81B0 ;
  border: #979797 1px solid;
}

.buttonDisable {
  background: #F3F5F9;
  color: #D5D5D5;
  border: #DFDFDF 1px solid;
}


.beian {
  width: 100%;
  text-align: center;
  background-color: #5E81B0;
  position: fixed;
  bottom: 0;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  .beianLink {
    color: #fff;
    font-size: 22px;
    margin-right: 40px;
    margin-left: 4px;
  }
}

