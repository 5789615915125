.chatLeft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
    .iconAndContent {
        display: flex;
        align-items: flex-start;
        .leftAvatar {
            width: 55px;
            height: 55px;
            margin-left: 10px;
            .robot {
                width: 100%;
            }
        }
        .leftContent, .leftContentWithSmallMargin {
            width: 1255px;
            margin-left: 50px;
            padding: 25px 30px;
            border-radius: 8px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(151, 151, 151, 1);
            white-space: pre-wrap;
            line-height: 30px;
        }
        .leftContentWithSmallMargin {
            line-height: 30px;
            margin-left: 20px;
            width: 80%;
            border: 1px solid #D3D3D3;
            font-family: Optima-Regular;
            font-size: 22px;
            color: #3D3D3D;
            letter-spacing: 0;
            font-weight: 400;
        }
    }
    .like {
        margin-left: 20px;
        margin-right: 20px;
        flex-shrink: 0;
        width: 56px;
        // height: 56px;
        cursor: pointer;
        img {
            width: 100%;
        }
    }

    .selectBtn {
        width: 200px;
        margin-top: 30px;
    }
}

.chatRight {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 30px;
    .rightAvatar {
        width: 106px;
        height: 106px;
        .user {
            width: 100%;
        }
    }
    .rightContent, .rightContentWithSmallMargin {
        width: 955px;
        margin-right: 50px;
        padding: 25px 30px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(151, 151, 151, 1);
        white-space: pre-wrap;
        background: rgba(227, 227, 227, 1);
    }
    .rightContentWithSmallMargin {
        margin-right: 30px;
        width: 50%;
        background: #5E81B0;
        font-family: Optima-Regular;
        font-size: 22px;
        color: #FFFFFF;
        letter-spacing: 0;
        font-weight: 400;
        position: relative;
    }
    .rightContentWithSmallMargin::before {
        content: "";
        position: absolute;
        right: -15px; /* 将三角形置于气泡右边 */
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 10px 15px; /* 设置三角形的大小和方向 */
        border-color: transparent transparent transparent #5E81B0; /* 顶角朝右，左侧有颜色 */
      }
}

.titleCon {
    height: 90px;
    background-color: #FFFFFF;
    font-family: Optima-Regular;
    font-size: 28px;
    color: #131415;
    letter-spacing: 0;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #979797;
    
    .title {
        color: rgba(76, 76, 76, 1);
    }

    position: relative;

    .btns {
        position: absolute;
        right: 14px;
        width: 102px;
        height: 46px;
        border-radius: 23px;
        border: 1px solid rgba(151, 151, 151, 1);
        display: flex;
        cursor: pointer;


        .icon {
            width: 52px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .iconStar {
            width: 52px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-left: 1px solid rgba(151, 151, 151, 1);
        }
        .clock {
            width: 22px;
            height: 25px;
        }

        .star {
            width: 22px;
            height: 21px;
        }
    }
}

.helps {
    // height: 100%;
    height: calc(100% - 168px - 80px);
    overflow-y: scroll;
}

.cons {
    align-self: flex-end;
    display: flex;
    height: 168px;
    // border-radius: 8px 8px, 0px, 0px;
    border-top: 1px solid rgba(151, 151, 151, 0.5);

    textarea {
        padding: 18px;
        border: none;
        resize: none;
        flex: 1;
    }

    .askBtn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: rgba(234, 247, 238, 1);
        border: 1px solid rgba(37, 179, 80, 1);
        width: 123px;
        flex-direction: column;
        color: rgba(47, 178, 85, 1);
        font-size: 20px;
        margin: 18px 11px;
        // border-left: 1px solid rgba(151, 151, 151, 0.5);
    }
}

.wrapper {
    height: 100%;
    .footer {
        text-align: center;
        margin-top: 30px;

        .submit {
            width: 335px;
            font-family: Optima-Regular;
            font-size: 22px;
            color: #5E81B0;
            border: 1px solid #5E81B0;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
        }
        .submitTxt {
            margin-top: 15px;
            font-family: Optima-Regular;
            font-size: 19px;
            color: #CCCCCC;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
        }
    }
}

.askHelp {
    height: 72px;
    display: flex;
    align-items: center;

    .helpBtn {
        border: 1px solid rgba(113, 129, 176, 1);
        border-radius: 8px;
        color: rgba(113, 129, 176, 1);
        background-color: rgba(242, 245, 255, 1);
        width: 123px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 22px;
        cursor: pointer;
    }
}

.fullScreen {
    height: calc(100% - 70px - 3px);
}