.body {
    text-align: center;
    .upload {
        display: flex;
        margin-top: 8px;
        justify-content: flex-start;
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #131415;
        letter-spacing: 0;
        line-height: 21px;
        font-weight: 400;
        .uploadBtn {
            border: 1px solid #5E81B0;
            font-family: Optima-Regular;
            font-size: 22px;
            color: #5E81B0;
            letter-spacing: 0;
            font-weight: 400;
            margin-right: 20px;
        }
        .uploadTxt {
            font-family: PingFangSC-Regular;
            font-size: 20px;
            margin-top: 20px;
            color: #131415;
            letter-spacing: 0;
            line-height: 21px;
            font-weight: 400;
        }
    }
    .summary {
        width: 100%;
        margin-top: 8px;
        font-family: Optima-Regular;
        font-size: 24px;
        color: #131415;
        letter-spacing: 0;
        line-height: 80px;
        font-weight: 400;
        text-align: left;
        .summaryDesc {
            font-size: 22px;
            color: #959595;
            letter-spacing: 0;
            line-height: 17px;
            font-weight: 400;
            margin-left: 15px;
        }
        .textarea {
            flex: 1;
            width: 100%;
            border: none;
            font-family: Optima-Regular;
            font-size: 24px;
            color: #131415;
            letter-spacing: 0;
            font-weight: 400;
            resize: none;
            outline: none;
            height: 400px;
            overflow-y: scroll;
            line-height: 45px;
            
            background-attachment: local;
            background-image:
                linear-gradient(to right, white 10px, transparent 10px),
                linear-gradient(to left, white 10px, transparent 10px),
                repeating-linear-gradient(white, white 50px, #979797 50px, #979797 51px, white 51px);
            line-height: 51px;
            padding: 8px 10px;
        }
        .summaryContent {
            font-family: Optima-Regular;
            font-size: 22px;
            color: #131415;
            letter-spacing: 0;
            line-height: 39px;
            font-weight: 400;
            height: 420px;
            overflow-y: scroll;
        }
    }
    .reviewBtn {
        width: 335px;
        border: 1px solid #5E81B0;
        font-family: Optima-Regular;
        font-size: 24px;
        color: #5E81B0;
        letter-spacing: 0;
        font-weight: 400;
    }
}