.body {
    padding: 0 20px;
    .addSubConcept {
        float:right;
        
        .addSubConcept {
            font-family: Optima-Regular;
            font-size: 19px;
            color: #5E81B0;
            letter-spacing: 0;
            font-weight: 400;
            line-height: 40px;
        }
    }
    .input {
        input {
            margin-top: 20px;
            width: 100%;
            border: none;
            border-bottom: 1px solid #979797;
            font-family: Optima-Regular;
            font-size: 22px;
            color: #131415;
            letter-spacing: 0;
            font-weight: 400;
            outline: none;
        }
    }
    .definition, .relevant {
        font-family: Optima-Regular;
        font-size: 26px;
        color: #131415;
        letter-spacing: 0;
        font-weight: 400;
        .title {
            margin-bottom: 20px;
        }
        .tag {
            border: 1px solid #5E81B0;
            font-family: Optima-Regular;
            font-size: 24px;
            letter-spacing: 0;
            font-weight: 400;
            width: 80px;
        }
    }
    .definition {
        margin-top: 10px;
    }
    .relevant {
        margin: 20px 0;
    }
    .subConceptGroup {
        .subConcept {
            .item {
                display: flex;
                align-items: flex-start;
                margin: 20px 0;
                border-left: 6px solid #D8D8D8;
               
                .right {
                    margin-left: 20px;
                    padding: 10px 0;
                    flex: 1;
                    .input {
                        .titleBox {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                        .close {
                            cursor: pointer;
                        }
                        .title {
                            font-family: Optima-Regular;
                            font-size: 26px;
                            color: #5E81B0;
                            letter-spacing: 0;
                            font-weight: 400;
                            input {
                                font-size: 16px;
                            }
                        }
                    }
                    .subDefinition {
                        font-family: Optima-Regular;
                        font-size: 24px;
                        color: #131415;
                        letter-spacing: 0;
                        font-weight: 400;
                        .title {
                            margin: 20px 0;
                        }
                        .tag {
                            border: 1px solid #5E81B0;
                            font-family: Optima-Regular;
                            font-size: 24px;
                            letter-spacing: 0;
                            font-weight: 400;
                            width: 80px;
                        }
                    }
                }
            }
        }
    }
}