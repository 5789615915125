.page {
    padding: 20px 240px 46px 240px;
}

.title {
    font-family: PingFangSC-Semibold;
    font-size: 50px;
    color: #3F3F3F;
    letter-spacing: 0;
    font-weight: 600;
}

.add {
    margin: 6px 0 40px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.addUser {
    background-color: #2FB255;
    border-radius: 8px;
    width: 225px;
    display: flex;
    align-items: center;
    text-align: center;
    font-family: PingFangSC-Regular;
    font-size: 30px;
    color: #FFFFFF;
    letter-spacing: 0;
    font-weight: 400;
    justify-content: center;
    padding: 5px 15px;
    line-height: 42px;
    cursor: pointer;
  }

  .name, .gender, .class, .email {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .userInfo {
    width: 180px;
  }

  .selector {
    width: 410px;
  }
  
  .input {
    width: 400px;
    border-style: none;
    /* font-family: PingFangSC-Regular;
    font-size: 25px;
    color: #6D7278; */
    border: 1px solid #D7D7D7;
    border-radius: 8px;
    height: 50px;
  }

  .btnGroup {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 60px;
  }

  .modalConfirm, .modalCancel, .disabled {
    border-radius: 8px;
    width: 20%;
    font-family: PingFangSC-Regular;
    font-size: 30px;
    color: #FFFFFF;
    letter-spacing: 0;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
  }

  .modalConfirm {
    background: #2FB255;
  }

  .disabled {
    background: #F3F5F9;
  }

  .modalCancel {
    margin-left: 52px;
    background: #FFFFFF;
    color: #898989;
    border: 1px solid #979797;
  }

  // .table {
  //   :global {
  //     .ant-table-thead {
  //       .ant-table-cell {
  //         background-color: #3c66c7;
  //         color: #fff;
  //       }
  //     }
  //   }
  // }
  
  .operation {
    display: flex;
  }

  .divider {
    margin: 0 10px;
  }

  .ban, .allow {
    text-decoration: underline;
    cursor: pointer;
  }

  .operationDisabled {
    text-decoration: underline;
    color: #898989;
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }